import React from 'react'
import { SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { AlignCenter, Container, FlexBoxSA, FlexBoxSB, Headline, Sandwich, SubTitle } from '../../../global/global.styles'
import TeamBlock from './teamBlock'
import IMAGEPH from '../../../assets/images/imagePH.jpg'
import PDIMAGE from '../../../assets/content/pritam.png'
import useWindowDimensions from '../../../global/components/screenSize'
import { Grid } from '@mui/material'

const Team = () => {

    const { height, width } = useWindowDimensions();

    return (
        <>
            <Container>
                <Sandwich>
                    <AlignCenter>
                        <Headline>
                            Our offices and the team
                        </Headline>
                        <SpacerTopBottomXL />
                        <FlexBoxSA>
                            <SubTitle style={{ width: 800 }}>
                                We’re based out of offices in New Jersey, USA and Bangalore, India. Our setup permits us to work closely with our clients, hire top talent across two continents, be cost-efficient and as a result keep over delivering to our clients.
                            </SubTitle>
                        </FlexBoxSA>
                    </AlignCenter>
                    <SpacerTopBottomXL />
                    <Sandwich>
                        <Grid container>
                            {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                style={
                                    width > 1100
                                        ?
                                        { paddingTop: 0 }
                                        :
                                        { paddingTop: 40 }
                                }>
                                <FlexBoxSA>
                                    <TeamBlock
                                        image={PDIMAGE}
                                        name={"Pritam Deshraj"}
                                        role={"Project Manager"}
                                    />
                                </FlexBoxSA>
                            </Grid> */}
                            {/* <Grid item xl={4} lg={4} md={12} sm={12} xs={12}
                                style={
                                    width > 1100
                                        ?
                                        { paddingTop: 0 }
                                        :
                                        { paddingTop: 40 }
                                }>
                                <FlexBoxSA>
                                    <TeamBlock
                                        image={IMAGEPH}
                                        name={"Varun Gopal"}
                                        role={"Project Manager"}
                                        description={"Lead Paragraph. Description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sollicitudin morbi sodales varius."}
                                    />
                                </FlexBoxSA>
                            </Grid> */}
                        </Grid>
                    </Sandwich>
                </Sandwich>
            </Container>
        </>
    )
}

export default Team
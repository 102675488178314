// DEFAULT
import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../../design/spacer/spacer'


// STYLES
import { AlignCenter, Container, FlexBoxSA, FlexBoxSB, Heading, Headline, Sandwich, SubTitle } from '../../../global/global.styles'
import ValuePropBlock from './valuePropBlock'


// COMPONENTS
import MOBILE from '../../../assets/icons/mobile.png'
import WEBSITE from '../../../assets/icons/website.png'
import PRODUCT from '../../../assets/icons/product.png'
import ANALYTICS from '../../../assets/icons/analytics.png'
import { Grid } from '@mui/material'
import useWindowDimensions from '../../../global/components/screenSize'

// FUNCTIONS


// LIBRARIES


// MAIN
export const ValueProposition = () => {

    const { height, width } = useWindowDimensions();

    return (
        <>
            <Container>
                <Sandwich>
                    <Sandwich>
                        <Grid container>
                            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                                <FlexBoxSA>
                                    <ValuePropBlock
                                        icon={MOBILE}
                                        title={"Mobile Apps"}
                                    />
                                </FlexBoxSA>
                            </Grid>
                            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}
                                style={
                                    width > 450
                                        ?
                                        { paddingTop: 0 }
                                        :
                                        { paddingTop: 40 }
                                }>
                                <FlexBoxSA>
                                    <ValuePropBlock
                                        icon={WEBSITE}
                                        title={"Website Development"}
                                    />
                                </FlexBoxSA>
                            </Grid>
                            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}
                                style={
                                    width > 1300
                                        ?
                                        { paddingTop: 0 }
                                        :
                                        { paddingTop: 40 }
                                }>
                                <FlexBoxSA>
                                    <ValuePropBlock
                                        icon={PRODUCT}
                                        title={"Product Design"}
                                    />
                                </FlexBoxSA>
                            </Grid>
                            <Grid item xl={3} lg={6} md={6} sm={6} xs={12}
                                style={
                                    width > 1300
                                        ?
                                        { paddingTop: 0 }
                                        :
                                        { paddingTop: 40 }
                                }>
                                <FlexBoxSA>
                                    <ValuePropBlock
                                        icon={ANALYTICS}
                                        title={"Analytics"}
                                    />
                                </FlexBoxSA>
                            </Grid>
                        </Grid>
                    </Sandwich>
                </Sandwich>
            </Container>
        </>
    )
}

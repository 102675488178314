import React from 'react'
import pic from '../../../assets/images/offices.png'
import useWindowDimensions from '../../../global/components/screenSize'
import { Sandwich } from '../../../global/global.styles'
import { LocationPic, LocationPicHolder } from '../style/home.styles'

const Location = () => {

    const { height, width } = useWindowDimensions();

    return (
        <>
            <Sandwich>
                <Sandwich>
                    <LocationPicHolder>
                        <LocationPic src={pic} />
                    </LocationPicHolder>
                </Sandwich>
            </Sandwich>
        </>
    )
}

export default Location
import React from 'react'
import { Body, Container, FlexBoxSB, Headline, Sandwich } from '../../../global/global.styles'
import { AboutImage, AboutImageContainer } from '../style/about.styles'
import ABOUTIMAGE from '../../../assets/images/aboutImage.png'
import { SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../global/components/screenSize'

const AboutUsSection = () => {

    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    return (
        <>
            <Container>
                <Sandwich>
                    <FlexBoxSB>
                        {
                            width > 650
                            &&
                            <AboutImageContainer>
                                <AboutImage src={ABOUTIMAGE} />
                            </AboutImageContainer>
                        }
                        <div>
                            <Headline>
                                About Us
                            </Headline>
                            <SpacerTopBottomXL />
                            <Body style={width < 350 ? { width: 280 } : width < 650 ? { width: 320 } : width < 850 ? { width: 340 } : width < 1100 ? { width: 440 } : { width: 568 }}>
                                DevX Labs is a software development company providing full cycle of services to start-ups, mid-size companies and large enterprises. With more than 10 years of combined experience in the industry, we have been able to strategically guide enterprises globally to transform their business by creating effective digital solutions. We ourselves have launched digital products, accumulated enormous knowledge and evolved into an organization. Our team has the skills and experience to fully understand your needs and handle any business challenge with confidence from product design, software development to growth marketing.
                            </Body>
                            <SpacerTopBottomXL />
                            <Button variant='contained'
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: "#307EF3",
                                    fontFamily: 'Manrope',
                                    fontWeight: 600,
                                    fontSize: 18,
                                    width: 104,
                                    borderRadius: '6px',
                                    ':hover': {
                                        bgcolor: '#2C6AC8',
                                    }
                                }}
                                onClick={() => navigate("/contact")}>
                                Contact
                            </Button>
                        </div>
                    </FlexBoxSB>
                </Sandwich>
            </Container>
        </>
    )
}

export default AboutUsSection
// DEFAULT
import React from 'react'
import { Footer } from '../../global/components/footer'
import Navbar from '../../global/components/navbar'
import ScrollToTop from '../../global/components/scrollToTop'

// STYLES
import { Container } from '../../global/global.styles'
import Approach from './components/approach'
import ServicesSection from './components/servicesSection'
import { Template } from './style/services.styles'

// COMPONENTS


// FUNCTIONS


// LIBRARIES


// MAIN
export const Services = () => {
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <ServicesSection />
            <Approach />
            <Footer />
        </>
    )
}

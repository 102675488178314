import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { ProcessBlockContainer, ProcessIcon, ProcessIconContainer } from '../style/home.styles'
import LOCATION from '../../../assets/icons/location.png'
import { AlignCenter, Body, FlexBoxSA, FlexColumnSB, Sandwich, SandwichHalf, SubHeading } from '../../../global/global.styles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const ProcessBlock = (props, children) => {
    return (
        <>
            <ProcessBlockContainer
                onMouseEnter={props.mouseEnter}
                onMouseLeave={props.mouseLeave}
                style={
                    props.expand
                        ?
                        { height: 'auto' }
                        :
                        { height: 280 }
                }>
                <FlexColumnSB style={{ height: '100%' }}>
                    <div>
                        <FlexBoxSA>
                            <ProcessIconContainer>
                                <ProcessIcon src={LOCATION} />
                            </ProcessIconContainer>
                        </FlexBoxSA>
                        <SandwichHalf />
                        <AlignCenter>
                            <SubHeading>
                                {props.title}
                            </SubHeading>
                            <SpacerTopBottomSmall />
                            <Body>
                                {props.subTitle}
                            </Body>
                            <SpacerTopBottomSmall />
                        </AlignCenter>
                    </div>
                    {
                        props.hovered
                            ?
                            <>
                                <SpacerTopBottomMedium />
                                <FlexBoxSA>
                                    {props.content}
                                </FlexBoxSA>
                            </>
                            :
                            <AlignCenter>
                                <ExpandMoreRoundedIcon
                                    sx={{
                                        color: '#307EF3',
                                        fontSize: 40
                                    }}
                                />
                            </AlignCenter>
                    }
                </FlexColumnSB>
            </ProcessBlockContainer>
        </>
    )
}

export default ProcessBlock
import React from 'react'
import useWindowDimensions from '../../global/components/screenSize';
import Lottie from 'react-lottie';
import notFoundGif from '../../assets/animations/404page.json'
import { Button } from '@mui/material';
import { Container, FlexBoxSA } from '../../global/global.styles';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
  position: 'relative',
  height: '100vh',
  width: '100vw'
}

const contentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)'
}

const NotFound = () => {

  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundGif,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Lottie
          options={defaultOptions}
          width={width > 1100 ? 700 : width > 650 ? 480 : width > 350 ? 320 : 200}
        />
      </div>
      <Container style={{ position: 'absolute', bottom: 0, right: 0 }}>
        <FlexBoxSA>
          <Button
            variant='contained'
            color='success'
            onClick={() => navigate("/")}>
            Return Home
          </Button>
        </FlexBoxSA>
      </Container>
    </div>
  )
}

export default NotFound
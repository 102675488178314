import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { AlignCenter, FlexBoxSA, SubHeading } from '../../../global/global.styles'
import { ValuePropBlockContainer, ValuePropIcon, ValuePropIconContainer } from '../style/home.styles'

const ValuePropBlock = (props) => {
    return (
        <ValuePropBlockContainer>
            <div>
                <FlexBoxSA>
                    <ValuePropIconContainer>
                        <ValuePropIcon src={props.icon} />
                    </ValuePropIconContainer>
                </FlexBoxSA>
                <SpacerTopBottomLarge />
                <AlignCenter>
                    <SubHeading>
                        {props.title}
                    </SubHeading>
                </AlignCenter>
            </div>
        </ValuePropBlockContainer>
    )
}

export default ValuePropBlock
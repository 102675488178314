import React from 'react'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { Caption } from '../../../global/global.styles';
import { SpacerLeftRightLarge, SpacerLeftRightSmall, SpacerRightMedium, SpacerRightSmall } from '../../../design/spacer/spacer';

const ProcessContent = (props) => {
    return (
        <>
            {
                props.block === "Discover"
                    ?
                    <table>
                        <tbody>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Market research
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        User Journey
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Personas & Segmentation
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Understand pain points
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Evaluate ideas & key solutions
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Tech stack evaluation
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        User acquisition & growth
                                    </Caption>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    props.block === "Design"
                        ?
                        <table>
                            <tbody>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Design thinking
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Wireframe
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Design user experience (UX)
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Design user interface (UI)
                                        </Caption>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        props.block === "Develop"
                        &&
                        <table>
                            <tbody>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Frontend
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Backend
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Quality Assurance
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Plan a sprint
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Review & ship in increment
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Improve & repeat
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Repeat & maintenance
                                        </Caption>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
            }
        </>
    )
}

export default ProcessContent
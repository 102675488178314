import React from 'react'
import { SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { Body, Container, Headline, Sandwich } from '../../../global/global.styles'

const ContactHeading = () => {
    return (
        <>
            <Sandwich>
                <Headline>
                    Contact Us
                </Headline>
                <SpacerTopBottomMedium />
                <Body>
                    Do you have a project for us? Let's Discuss
                </Body>
            </Sandwich>
        </>
    )
}

export default ContactHeading
// DEFAULT
import React from 'react'

// STYLES
import { HeroSectionContainer, MainHeadline, MobileHeroSectionContainer } from '../style/home.styles'
import { Body, Container, FlexBox, FlexColumnSA, Headline, Sandwich } from '../../../global/global.styles'

// COMPONENTS
import { SpacerLeftRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'

// FUNCTIONS


// LIBRARIES
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material'
import { fontWeight } from '@mui/system'
import useWindowDimensions from '../../../global/components/screenSize';


// MAIN
export const HeroSection = () => {

    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    return (
        <>
            {
                width > 450
                    ?
                    <HeroSectionContainer>
                        <Container>
                            <div>
                                <MainHeadline>
                                    We Design,
                                </MainHeadline>
                                <MainHeadline>
                                    We Build & We Launch
                                </MainHeadline>
                                <SpacerTopBottomLarge>
                                    <Body style={width > 850 ? { width: 500 } : width > 1100 ? { width: 400 } : { width: 300 }}>
                                        Whether we’re developing a new product or enhancing an existing product, DevX Labs works with clients to grow businesses and empower people.
                                    </Body>
                                </SpacerTopBottomLarge>
                                <FlexBox>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: "#307EF3",
                                            fontFamily: 'Manrope',
                                            fontWeight: 600,
                                            fontSize: width > 850 ? 18 : 12,
                                            width: width > 850 ? 104 : 80,
                                            borderRadius: '6px',
                                            ':hover': {
                                                bgcolor: '#2C6AC8',
                                            }
                                        }}
                                        onClick={() => navigate("/about")}>
                                        About
                                    </Button>
                                    <SpacerLeftRightMedium />
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            textTransform: 'none',
                                            fontFamily: 'Manrope',
                                            fontWeight: 600,
                                            fontSize: width > 850 ? 18 : 12,
                                            width: width > 850 ? 104 : 80,
                                            borderRadius: '6px',
                                            color: "#307EF3"
                                        }}
                                        onClick={() => navigate("/contact")}>
                                        Contact
                                    </Button>
                                </FlexBox>
                            </div>
                        </Container>
                    </HeroSectionContainer>
                    :
                    <MobileHeroSectionContainer>
                        <Container>
                            <div>
                                <MainHeadline>
                                    We Design,
                                </MainHeadline>
                                <MainHeadline>
                                    We Build &
                                </MainHeadline>
                                <MainHeadline>
                                    We Launch
                                </MainHeadline>
                                <SpacerTopBottomLarge>
                                    <Body style={{ width: 200 }}>
                                        Whether we’re developing a new product or enhancing an existing product, DevX Labs works with clients to grow businesses and empower people.
                                    </Body>
                                </SpacerTopBottomLarge>
                                <FlexBox style={width > 370 ? { flexDirection: 'row' } : { flexDirection: 'column', alignItems: 'normal' }}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: "#307EF3",
                                            fontFamily: 'Manrope',
                                            fontWeight: 600,
                                            fontSize: width > 850 ? 18 : 12,
                                            width: width > 850 ? 104 : 80,
                                            borderRadius: '6px',
                                            ':hover': {
                                                bgcolor: '#2C6AC8',
                                            }
                                        }}
                                        onClick={() => navigate("/about")}>
                                        About
                                    </Button>
                                    {
                                        width > 370
                                            ?
                                            <SpacerLeftRightMedium />
                                            :
                                            <SpacerTopBottomSmall />
                                    }
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            textTransform: 'none',
                                            fontFamily: 'Manrope',
                                            fontWeight: 600,
                                            fontSize: width > 850 ? 18 : 12,
                                            width: width > 850 ? 104 : 80,
                                            borderRadius: '6px',
                                            color: "#307EF3"
                                        }}
                                        onClick={() => navigate("/contact")}>
                                        Contact
                                    </Button>
                                </FlexBox>
                            </div>
                        </Container>
                    </MobileHeroSectionContainer>
            }
        </>
    )
}

import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SpacerLeftRightXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../design/spacer/spacer'
import { FlexBox, HamburgerContainer, MenuContainer } from '../global.styles';


export default function SideBar() {

    let activeStyle = {
        color: "#307EF3",
        textDecoration: "none",
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "100%",
        letterSpacing: "-0.02em"
    };

    let inActiveStyle = {
        color: "black",
        textDecoration: "none",
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "100%",
        letterSpacing: "-0.02em"
    };

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <MenuContainer>
                    <NavLink
                        to="/"
                        style={({ isActive }) =>
                            isActive ? activeStyle : inActiveStyle
                        }>
                        Home
                    </NavLink>
                    <SpacerLeftRightXL />
                    <NavLink
                        to="/work"
                        style={({ isActive }) =>
                            isActive ? activeStyle : inActiveStyle
                        }>
                        Work
                    </NavLink>
                    <SpacerLeftRightXL />
                    <NavLink
                        to="/about"
                        style={({ isActive }) =>
                            isActive ? activeStyle : inActiveStyle
                        }>
                        About
                    </NavLink>
                    <SpacerLeftRightXL />
                    <NavLink
                        to="/contact"
                        style={({ isActive }) =>
                            isActive ? activeStyle : inActiveStyle
                        }>
                        Contact
                    </NavLink>
                </MenuContainer>
            </List>
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment>
                    <HamburgerContainer onClick={toggleDrawer(anchor, true)}>
                        <MenuRoundedIcon
                            size="large"
                            sx={{ color: 'white' }}
                        />
                    </HamburgerContainer>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
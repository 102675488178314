export const fonts = {
  // heading: "Oswald_400Regular",
  // body: "Lato_400Regular",
  // bodyBold: "Lato_700Bold",
  // monospace: "Oswald_400Regular",
  // number: "Lato_400Regular",
};

export const fontWeights = {
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  // black: 900,
};

export const fontSizes = {
  headline: "64px",
  // subHeadline: "80px",
  heading: "40px",
  subHeading: "32px",
  title: "24px",
  subTitle: "22px",
  body: "20px",
  subBody: "18px",
  caption: "16px",
  // subCaption: "10px",
};
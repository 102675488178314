// DEFAULT
import React from 'react'
import { SpacerTopBottomXL } from '../../design/spacer/spacer'
import { Footer } from '../../global/components/footer'
import Navbar from '../../global/components/navbar'
import ScrollToTop from '../../global/components/scrollToTop'

// STYLES
import { Container } from '../../global/global.styles'
import CTA from './components/cta'
import { HeroSection } from './components/heroSection'
import Location from './components/location'
import Process from './components/process'
import Team from './components/team'
import { ValueProposition } from './components/valueProposition'
import { Template } from './style/home.styles'

// COMPONENTS


// FUNCTIONS


// LIBRARIES


// MAIN
export const Home = () => {
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <HeroSection />
            <ValueProposition />
            <Process />
            <Team />
            <Location />
            <CTA />
            <Footer />
        </>
    )
}

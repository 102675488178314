import React from 'react'
import { SpacerLeftRightXL } from '../../design/spacer/spacer'
import { BodySemiBold, FlexBox, FlexBoxSB, NavBarContainer, NavItem } from '../global.styles'
import { NavLink } from 'react-router-dom'
import SideBar from './sideBar'
import useWindowDimensions from './screenSize'

const Navbar = () => {

    const { height, width } = useWindowDimensions();

    let activeStyle = {
        color: "#307EF3",
        textDecoration: "none",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "100%",
        letterSpacing: "-0.02em"
    };

    let inActiveStyle = {
        color: "black",
        textDecoration: "none",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "100%",
        letterSpacing: "-0.02em"
    };

    return (
        <>
            {
                width > 650
                    ?
                    <NavBarContainer>
                        <FlexBoxSB>
                            <BodySemiBold>
                                DevX Labs
                            </BodySemiBold>
                            <FlexBox>
                                <NavLink
                                    to="/"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Home
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/work"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Work
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/about"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    About
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/contact"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Contact
                                </NavLink>
                            </FlexBox>
                        </FlexBoxSB>
                    </NavBarContainer>
                    :
                    <NavBarContainer>
                        <FlexBoxSB>
                            <BodySemiBold>
                                DevX Labs
                            </BodySemiBold>
                            <SideBar />
                        </FlexBoxSB>
                    </NavBarContainer>

            }
        </>
    )
}

export default Navbar
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Container = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[30]};
        padding-right: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[15]};
        padding-right: ${(props) => props.theme.space[15]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[7]};
        padding-right: ${(props) => props.theme.space[7]};
    }

    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const Squeezer = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[30]};
        padding-right: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[15]};
        padding-right: ${(props) => props.theme.space[15]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[7]};
        padding-right: ${(props) => props.theme.space[7]};
    }

    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const SqueezerHalf = styled.div`
    padding-left: ${(props) => props.theme.space[10]};
    padding-right: ${(props) => props.theme.space[10]};
`;

export const Sandwich = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
`;

export const SandwichHalf = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
`;

// ----------------------------------------TEXT----------------------------------------//

export const Headline = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
        
    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {
        font-size: ${(props) => props.theme.fontSizes.heading}; 
    }
    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
`;

export const HeadlineWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;

    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {
        font-size: ${(props) => props.theme.fontSizes.heading}; 
    }
    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
    @media only screen and (max-width: 850px) {

    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const Heading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const HeadingWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const SubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;

    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const SubHeadingPrimary = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.primary.blue};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const Title = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 700;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const SubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 400;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const SubTitleWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 400;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;

    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {

    }
    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.body};
    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.caption};
    }
`;

export const Body = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 400;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;

    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {

    }
    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.caption};
    }
    @media only screen and (max-width: 650px) {

    }
`;

export const SubBody = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 400;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const SubBodyBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const BodySemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const Caption = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 400;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const CaptionWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 400;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

export const CaptionWhiteBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 700;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
`;

// ----------------------------------------FLEX----------------------------------------//

export const FlexBoxSB = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexBoxSA = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const FlexBox = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnSA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const FlexColumnSB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FlexColumnSE = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

// ----------------------------------------ALIGNMENT----------------------------------------//

export const AlignCenter = styled.div`
    text-align: center;
`;

// ----------------------------------------NAVBAR----------------------------------------//

export const NavBarContainer = styled.div`
    padding-top: ${(props) => props.theme.space[5]};
    padding-bottom: ${(props) => props.theme.space[5]};
    padding-left: ${(props) => props.theme.space[8]};
    padding-right: ${(props) => props.theme.space[8]};
    background-color: ${(props) => props.theme.colors.bg.white};
    box-shadow: 0px 5px 10px 2px rgba(199,199,199,0.75);
-webkit-box-shadow: 0px 5px 10px 2px rgba(199,199,199,0.75);
-moz-box-shadow: 0px 5px 10px 2px rgba(199,199,199,0.75);
    ${'' /* position: fixed;
    top: 0;
    width: 100vw; */}

    @media only screen and (max-width: 450px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 370px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const NavItem = styled(NavLink)`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
    text-decoration: none;
`;

export const HamburgerContainer = styled.div`
    background-color: ${(props) => props.theme.colors.bg.black};
    border-radius: 4px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
`;

export const MenuContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vh;

    @media only screen and (max-width: 400px) {
        
    }

    @media only screen and (max-width: 350px) {
        height: 60vh;
    }
`;


// ----------------------------------------FOOTER----------------------------------------//

export const FooterContainer = styled.div`
    background-color: ${(props) => props.theme.colors.bg.black};
`;
export const colors = {
  primary: {
    blue: "#307EF3",
  },
  bg: {
    white: "#FFFFFF",
    black: "#000000",
  },
  text: {
    white: "#FFFFFF",
    black: "#000000",
  }
};
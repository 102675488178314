import React from 'react'
import { Body, Container, FlexBox, FlexBoxSA, Headline, Sandwich } from '../../../global/global.styles'
import { ServiceManContainer, ServiceManImage } from '../style/services.styles'
import SERVICEMAN from '../../../assets/images/servicesMan.png'
import { Grid } from '@mui/material'
import { SpacerTopBottomLarge, SpacerTopBottomSmall } from '../../../design/spacer/spacer'

const ServicesSection = () => {
    return (
        <>
            <Container>
                <Sandwich>
                    <Headline>
                        Services
                    </Headline>
                </Sandwich>
                <Grid container>
                    <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                        <FlexBoxSA>
                            <div>
                                <Body>
                                    As a full-service software development company, product development services include – Mobile development, Web development, UI/UX design services, and Analytics.
                                </Body>
                                <SpacerTopBottomLarge />
                                <Body>
                                    We understand that every project has unique needs. We see it as an opportunity to build something great!
                                </Body>
                                <Body>
                                    We often get asked ,
                                </Body>
                                <Body>
                                    We don’t have a product but just an idea. Can you still help us?
                                </Body>
                                <Body>
                                    We need help getting our product grow. Can you assist us?
                                </Body>
                                <Body>
                                    We have a product that requires fine tuning. Can you support us in tweaking it?
                                </Body>
                                <SpacerTopBottomLarge />
                                <Body>
                                    The answer to all these questions -
                                </Body>
                                <Body>
                                    Yes, absolutely. We will work with you to build a great product!
                                </Body>
                                <SpacerTopBottomLarge />
                                <Body>
                                    We can help at any stage of the development process from product discovery, strategy, design, development, and testing including support and maintenance.
                                </Body>
                                <SpacerTopBottomLarge />
                                <Body>
                                    To be able to strategically guide you through your project with confidence we follow a proprietary approach -
                                </Body>
                            </div>
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <FlexBoxSA>
                            <FlexBox style={{ justifyContent: 'flex-end' }}>
                                <ServiceManContainer>
                                    <ServiceManImage src={SERVICEMAN} />
                                </ServiceManContainer>
                            </FlexBox>
                        </FlexBoxSA>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ServicesSection
import styled from 'styled-components'
import HEROIMAGE from '../../../assets/images/HeroImage.png'
import MOBILEHEROIMAGE from '../../../assets/images/HeroImageMobile.png'
import SERVICESIMAGE from '../../../assets/images/servicesImage.png'
import SERVICESIMAGEMOBILE from '../../../assets/images/servicesImageMobile.png'

// ----------------------------------------Containers----------------------------------------//

export const Template = styled.div`

    @media only screen and (min-width: 1800px) {

    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 370px) {

    }
`;

export const HeroSectionContainer = styled.div`
    background-image: url(${HEROIMAGE});
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;

    @media only screen and (min-width: 1800px) {
        background-size: cover;
        height: 90vh;
    }
    @media only screen and (max-width: 1300px) {
        height: 80vh;
    }
    @media only screen and (max-width: 1100px) {
        background-size: cover;
        height: 60vh;
    }
    @media only screen and (max-width: 850px) {
        height: 40vh;
    }
    @media only screen and (max-width: 650px) {
        height: 30vh;
    }
    @media only screen and (max-width: 370px) {

    }
`;

export const MobileHeroSectionContainer = styled.div`
    background-image: url(${MOBILEHEROIMAGE});
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;

    @media only screen and (max-width: 450px) {
        background-size: cover;
        height: 50vh;
    }

    @media only screen and (max-width: 370px) {
        height: 55vh;
    }

    @media only screen and (max-width: 350px) {
        height: 62vh;
    }
`;

export const MainHeadline = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 600;
    font-style: normal;
    line-height: 135%;
    letter-spacing: -0.02em;
        
    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {
        font-size: ${(props) => props.theme.fontSizes.heading}; 
    }
    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
    @media only screen and (max-width: 650px) {

    }
`;

export const ValuePropBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};
    box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-webkit-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-moz-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
    border-radius: 30px;
    width: 280px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
        width: 220px;
        height: 176px;
    }
`;

export const ValuePropIconContainer = styled.div`
   max-width: 24px;
   max-height: 32px;
`;

export const ValuePropIcon = styled.img`
   width: 100%;
   height: 100%;
`;

export const ProcessContainer = styled.div`
    background-image: url(${SERVICESIMAGE});
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80vh;

    @media only screen and (min-width: 1800px) {
        background-size: cover;
        height: 90vh;
    }
    @media only screen and (max-width: 1300px) {
        height: 80vh;
    }
    @media only screen and (max-width: 1100px) {
        background-size: cover;
        height: 60vh;
    }
    @media only screen and (max-width: 850px) {
        height: 40vh;
    }
    @media only screen and (max-width: 650px) {
        height: 30vh;
    }
    @media only screen and (max-width: 370px) {

    }
`;

export const MobileProcessContainer = styled.div`
    background-image: url(${SERVICESIMAGEMOBILE});
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80vh;

    @media only screen and (max-width: 850px) {
        height: 60vh;
    }

    @media only screen and (max-width: 450px) {
        background-size: cover;
        height: 50vh;
    }
    @media only screen and (max-width: 350px) {
        height: 62vh;
    }
`;

export const ProcessBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-webkit-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-moz-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
    border-radius: 30px;
    width: 340px;

    @media only screen and (max-width: 1300px) {
        width: 300px;
    }

    @media only screen and (max-width: 1100px) {
        width: 240px;
    }

    @media only screen and (max-width: 850px) {
        width: 340px;
    }

    @media only screen and (max-width: 450px) {
        width: 360px;
    }

    @media only screen and (max-width: 400px) {
        width: 320px;
    }

    @media only screen and (max-width: 350px) {
        width: 280px;
    }
`;

export const ProcessIconContainer = styled.div`
   max-width: 32px;
   max-height: 48px;
`;

export const ProcessIcon = styled.img`
   width: 100%;
   height: 100%;
`;

export const TeamBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[5]};
    padding-right: ${(props) => props.theme.space[5]};
    box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-webkit-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-moz-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
    border-radius: 30px;
    width: 340px;
`;

export const TeamImageContainer = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
`;

export const TeamImage = styled.img`
   width: 100%;
   height: 100%;
   border-radius: 50%;
`;

export const CtaIconContainer = styled.div`
   width: 48px;
   height: 48px;
`;

export const CtaIcon = styled.img`
   width: 100%;
   height: 100%;
`;

export const LocationPicHolder = styled.div`
    height: 360px;

    @media only screen and (max-width: 1100px) {
        height: 240px;
    }

    @media only screen and (max-width: 850px) {
        height: auto;
    }

    @media only screen and (max-width: 650px) {
       
    }

    @media only screen and (max-width: 450px) {
        
    }

    @media only screen and (max-width: 350px) {

    }

`;

export const LocationPic = styled.img`
   width: 100%;
   height: 100%;
`;
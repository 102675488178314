// DEFAULT
import React from 'react'
import { Footer } from '../../global/components/footer'
import Navbar from '../../global/components/navbar'
import ScrollToTop from '../../global/components/scrollToTop'

// STYLES
import { Container } from '../../global/global.styles'
import ContactFormSection from './components/contactFormSection'
import ContactHeading from './components/contactHeading'
import { Template } from './style/contact.styles'

// COMPONENTS


// FUNCTIONS


// LIBRARIES


// MAIN
export const Contact = () => {
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <Container>
                <ContactHeading />
                <ContactFormSection />
            </Container>
            <Footer />
        </>
    )
}

import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const Template = styled.div`

    @media only screen and (min-width: 1800px) {

    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 370px) {

    }
`;

export const ServiceManContainer = styled.div`
   width: 400px;
   height: 366px;
`;

export const ServiceManImage = styled.img`
   width: 100%;
   height: 100%;
`;

export const ApproachBlock = styled.div`
    padding-top: ${(props) => props.theme.space[5]};
    padding-bottom: ${(props) => props.theme.space[5]};
    padding-left: ${(props) => props.theme.space[5]};
    padding-right: ${(props) => props.theme.space[5]};
    box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-webkit-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
-moz-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
    border-radius: 30px;
    width: 560px;
    min-height: 280px;

    @media only screen and (min-width: 1800px) {

    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {
        width: 480px;
    }
    @media only screen and (max-width: 450px) {
        width: 340px;
    }
    @media only screen and (max-width: 370px) {
        width: 320px;
    }
    @media only screen and (max-width: 350px) {
        width: 280px;
    }
`;


import React from 'react'
import { SpacerRightSmall, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { Caption, Container, Sandwich, Squeezer, SqueezerHalf, SubHeadingPrimary } from '../../../global/global.styles'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { ApproachBlock } from '../style/services.styles';
import useWindowDimensions from '../../../global/components/screenSize';

const Approach = () => {

    const { height, width } = useWindowDimensions();

    return (
        <>
            <Squeezer>
                <ApproachBlock>
                    <SubHeadingPrimary>
                        Project Kick-off
                    </SubHeadingPrimary>
                    <SpacerTopBottomXL />
                    <table>
                        <tbody>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Initial team meet-up
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Understand the business, the product, & the user
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Discuss scope of opportunity
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Gather requirements and expectations
                                    </Caption>
                                </td>
                            </tr>
                            <tr style={{ alignItems: 'center' }}>
                                <td style={{ padding: '4px 0' }}>
                                    <CircleRoundedIcon
                                        sx={{
                                            fontSize: 12
                                        }}
                                    />
                                </td>
                                <td>
                                    <SpacerRightSmall />
                                </td>
                                <td>
                                    <Caption>
                                        Provide feedback
                                    </Caption>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ApproachBlock>
                <Sandwich />
                <div style={width > 850 ? { marginLeft: '10%' } : { marginLeft: 'auto' }}>
                    <ApproachBlock>
                        <SubHeadingPrimary>
                            Project Discovery
                        </SubHeadingPrimary>
                        <SpacerTopBottomXL />
                        <table>
                            <tbody>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Market research
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            User Journey
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Personas & segmentation
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Understand pain points
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Evaluate ideas & key solutions
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Tech stack evaluation
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Strategy – user acquisition and growth
                                        </Caption>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ApproachBlock>
                </div>
                <Sandwich />
                <div style={width > 850 ? { marginLeft: '20%' } : { marginLeft: 'auto' }}>
                    <ApproachBlock>
                        <SubHeadingPrimary>
                            Product Design
                        </SubHeadingPrimary>
                        <SpacerTopBottomXL />
                        <table>
                            <tbody>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Idea validation
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Wireframe
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Design User experience (UX)
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Design User Interface (UI)
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            User Testing
                                        </Caption>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ApproachBlock>
                </div>
                <Sandwich />
                <div style={width > 850 ? { marginLeft: '30%' } : { marginLeft: 'auto' }}>
                    <ApproachBlock>
                        <SubHeadingPrimary>
                            Product Development
                        </SubHeadingPrimary>
                        <SpacerTopBottomXL />
                        <table>
                            <tbody>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Plan the sprint
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Tech stack – iOS, Android, ReactNative, React.js, Node.js, Java
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Review & ship an increment
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Improve & repeat
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Support & Maintenance
                                        </Caption>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ApproachBlock>
                </div>
                <Sandwich />
                <div style={width > 850 ? { marginLeft: '40%' } : { marginLeft: 'auto' }}>
                    <ApproachBlock>
                        <SubHeadingPrimary>
                            Marketing
                        </SubHeadingPrimary>
                        <SpacerTopBottomXL />
                        <table>
                            <tbody>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            User acquisition
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            User engagement
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Marketing automation & optimization
                                        </Caption>
                                    </td>
                                </tr>
                                <tr style={{ alignItems: 'center' }}>
                                    <td style={{ padding: '4px 0' }}>
                                        <CircleRoundedIcon
                                            sx={{
                                                fontSize: 12
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <SpacerRightSmall />
                                    </td>
                                    <td>
                                        <Caption>
                                            Channels: Social, Email, Push, Display, Search engine
                                        </Caption>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ApproachBlock>
                </div>
                <Sandwich />
            </Squeezer>
        </>
    )
}

export default Approach
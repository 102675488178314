// DEFAULT
import React from 'react'
import { Link } from 'react-router-dom';
import { SpacerLeftRightXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../design/spacer/spacer'
import { AlignCenter, CaptionWhite, CaptionWhiteBold, Container, FlexBox, FlexBoxSA, FlexBoxSB, FooterContainer, HeadingWhite, Sandwich, Squeezer, SqueezerHalf } from '../global.styles'
import useWindowDimensions from './screenSize';

// STYLES

// COMPONENTS


// FUNCTIONS


// LIBRARIES


// MAIN
export const Footer = () => {

    const { height, width } = useWindowDimensions();

    return (
        <>
            {
                width > 850
                    ?
                    <FooterContainer>
                        <Container>
                            <Sandwich>
                                <HeadingWhite>
                                    DevX Labs
                                </HeadingWhite>
                                {
                                    width < 1300
                                    &&
                                    <Sandwich />
                                }
                                <FlexBoxSA>
                                    <FlexBoxSB style={{ alignItems: 'normal' }}>
                                        <div>
                                            {/* <CaptionWhite>
                                                General
                                            </CaptionWhite>
                                            <SpacerTopBottomLarge /> */}
                                            <Link to="/" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Home
                                                </CaptionWhite>
                                            </Link>
                                            <SpacerTopBottomLarge />
                                            <Link to="/about" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    About Us
                                                </CaptionWhite>
                                            </Link>
                                            <SpacerTopBottomLarge />
                                            <Link to="/services" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Services
                                                </CaptionWhite>
                                            </Link>
                                            <SpacerTopBottomLarge />
                                            <Link to="/work" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Our Work
                                                </CaptionWhite>
                                            </Link>
                                            <SpacerTopBottomLarge />
                                            <Link to="/contact" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Contact Us
                                                </CaptionWhite>
                                            </Link>
                                        </div>
                                        <SqueezerHalf />
                                        {/* <div>
                                            <CaptionWhite>
                                                Social
                                            </CaptionWhite>
                                            <SpacerTopBottomLarge />
                                            <Link to="/contact" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Contact Us
                                                </CaptionWhite>
                                            </Link>
                                            <SpacerTopBottomLarge />
                                            <CaptionWhite>
                                                Impact
                                            </CaptionWhite>
                                            <SpacerTopBottomLarge />
                                            <CaptionWhite>
                                                Blog
                                            </CaptionWhite>
                                        </div>
                                        <SqueezerHalf /> */}
                                        <div>
                                            <Link to="/cookies" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Cookies
                                                </CaptionWhite>
                                            </Link>
                                            <SpacerTopBottomLarge />
                                            <Link to="/privacy" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Privacy Policy
                                                </CaptionWhite>
                                            </Link>
                                            <SpacerTopBottomLarge />
                                            <Link to="/terms" style={{ textDecoration: 'none' }}>
                                                <CaptionWhite>
                                                    Terms of Use
                                                </CaptionWhite>
                                            </Link>
                                        </div>
                                    </FlexBoxSB>
                                </FlexBoxSA>
                            </Sandwich>
                            <SpacerTopBottomXL />
                            <AlignCenter>
                                <CaptionWhite>
                                    DevX Labs © 2022 Copyright. All rights reserved
                                </CaptionWhite>
                            </AlignCenter>
                        </Container>
                    </FooterContainer>
                    :
                    <FooterContainer>
                        <Container>
                            <Sandwich>
                                <HeadingWhite>
                                    DevX Labs
                                </HeadingWhite>
                                <Sandwich />
                                <div>
                                    {/* <CaptionWhite>
                                        General
                                    </CaptionWhite>
                                    <SpacerTopBottomLarge /> */}
                                    <Link to="/" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Home
                                        </CaptionWhite>
                                    </Link>
                                    <SpacerTopBottomLarge />
                                    <Link to="/about" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            About Us
                                        </CaptionWhite>
                                    </Link>
                                    <SpacerTopBottomLarge />
                                    <Link to="/services" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Services
                                        </CaptionWhite>
                                    </Link>
                                    <SpacerTopBottomLarge />
                                    <Link to="/work" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Our Work
                                        </CaptionWhite>
                                    </Link>
                                    <SpacerTopBottomLarge />
                                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Contact Us
                                        </CaptionWhite>
                                    </Link>
                                </div>
                                <Sandwich />
                                {/* <div>
                                    <CaptionWhite>
                                        Social
                                    </CaptionWhite>
                                    <SpacerTopBottomLarge />
                                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Contact Us
                                        </CaptionWhite>
                                    </Link>
                                    <SpacerTopBottomLarge />
                                    <CaptionWhite>
                                        Impact
                                    </CaptionWhite>
                                    <SpacerTopBottomLarge />
                                    <CaptionWhite>
                                        Blog
                                    </CaptionWhite>
                                </div>
                                <Sandwich /> */}
                                <div>
                                    <Link to="/cookies" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Cookies
                                        </CaptionWhite>
                                    </Link>
                                    <SpacerTopBottomLarge />
                                    <Link to="/privacy" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Privacy Policy
                                        </CaptionWhite>
                                    </Link>
                                    <SpacerTopBottomLarge />
                                    <Link to="/terms" style={{ textDecoration: 'none' }}>
                                        <CaptionWhite>
                                            Terms of Use
                                        </CaptionWhite>
                                    </Link>
                                </div>
                            </Sandwich>
                            <AlignCenter>
                                <CaptionWhite>
                                    DevX Labs © 2022 Copyright. All rights reserved
                                </CaptionWhite>
                            </AlignCenter>
                        </Container>
                    </FooterContainer>
            }
        </>
    )
}

// DEFAULT
import React from 'react'
import ScrollToTop from '../../global/components/scrollToTop'

// STYLES
import { Container } from '../../global/global.styles'
import { Template } from './style/projects.styles'

// COMPONENTS


// FUNCTIONS


// LIBRARIES


// MAIN
export const Projects = () => {
    return (
        <>
            <ScrollToTop />
            <Container>
                <Template>Projects</Template>
            </Container>
        </>
    )
}

import React, { useRef } from 'react'
import { Container, FlexBox, FlexBoxSA, FlexBoxSB, Squeezer } from '../../../global/global.styles'
import { ContactImage, ContactImageContainer } from '../style/contact.styles'
import CONTACTIMAGE from '../../../assets/images/contactImage.png'
import { Button, Grid, TextField } from '@mui/material'
import { SpacerTopBottomLarge, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import useWindowDimensions from '../../../global/components/screenSize'
import { useState } from 'react'
import { db } from '../../../firebase/firebase'
import { addDoc, collection } from 'firebase/firestore'
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab'
import emailjs from '@emailjs/browser';

const ContactFormSection = () => {

    const { height, width } = useWindowDimensions();

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [scope, setScope] = useState("")
    const [skill, setSkill] = useState("")
    const [budget, setBudget] = useState("")

    const [loading, setLoading] = useState(false)

    const leadsCollectionRef = collection(db, "leads")

    const form = useRef();

    const notifyCXO = () => {

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    const handleSubmit = async () => {


        setLoading(true)

        const data = {
            name,
            email,
            phone,
            scope,
            skill,
            budget
        }

        await addDoc(leadsCollectionRef, data)

        setLoading(false)
        notifyCXO(data)
        setName("")
        setEmail("")
        setPhone("")
        setScope("")
        setSkill("")
        setBudget("")
    }

    return (
        <>
            <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <form ref={form}>
                        <TextField
                            id="standard-basic"
                            label="Name"
                            variant="standard"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={
                                width > 450
                                    ?
                                    { width: 347 }
                                    :
                                    { width: 280 }
                            }
                        />
                        <SpacerTopBottomLarge />
                        <TextField
                            id="standard-basic"
                            label="Email"
                            name="email"
                            variant="standard"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={
                                width > 450
                                    ?
                                    { width: 347 }
                                    :
                                    { width: 280 }
                            }
                        />
                        <SpacerTopBottomLarge />
                        <TextField
                            id="standard-basic"
                            label="Phone Number"
                            name="phone"
                            variant="standard"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            sx={
                                width > 450
                                    ?
                                    { width: 347 }
                                    :
                                    { width: 280 }
                            }
                        />
                        <SpacerTopBottomLarge />
                        <TextField
                            id="standard-basic"
                            label="Tell us about your project & scope"
                            name="scope"
                            variant="standard"
                            value={scope}
                            onChange={(e) => setScope(e.target.value)}
                            sx={
                                width > 450
                                    ?
                                    { width: 347 }
                                    :
                                    { width: 280 }
                            }
                        />
                        <SpacerTopBottomLarge />
                        <TextField
                            id="standard-basic"
                            label="What skills do you need?"
                            name="skill"
                            variant="standard"
                            value={skill}
                            onChange={(e) => setSkill(e.target.value)}
                            sx={
                                width > 450
                                    ?
                                    { width: 347 }
                                    :
                                    { width: 280 }
                            }
                        />
                        <SpacerTopBottomLarge />
                        <TextField
                            id="standard-basic"
                            label="Your Budget"
                            name="budget"
                            variant="standard"
                            value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                            sx={
                                width > 450
                                    ?
                                    { width: 347 }
                                    :
                                    { width: 280 }
                            }
                        />
                    </form>
                    <SpacerTopBottomXL>
                        {
                            loading ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'none',
                                        fontFamily: 'Manrope',
                                        fontWeight: 600,
                                        fontSize: 18,
                                        width: 120,
                                        borderRadius: '6px'
                                    }}
                                >
                                    Send
                                </LoadingButton>
                                :
                                <Button
                                    variant='contained'
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: "#307EF3",
                                        fontFamily: 'Manrope',
                                        fontWeight: 600,
                                        fontSize: 18,
                                        width: 104,
                                        borderRadius: '6px',
                                        ':hover': {
                                            bgcolor: '#2C6AC8',
                                        }
                                    }}
                                    onClick={handleSubmit}>
                                    Send
                                </Button>
                        }
                    </SpacerTopBottomXL>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FlexBoxSA>
                        <ContactImageContainer>
                            <ContactImage src={CONTACTIMAGE} />
                        </ContactImageContainer>
                    </FlexBoxSA>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactFormSection
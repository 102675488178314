// DEFAULT
import React from 'react'
import { Footer } from '../../global/components/footer'
import Navbar from '../../global/components/navbar'
import ScrollToTop from '../../global/components/scrollToTop'

// STYLES
import { Container, FlexBoxSB, Sandwich } from '../../global/global.styles'
import AboutUsSection from './components/aboutUsSection'
import { Template } from './style/about.styles'

// COMPONENTS


// FUNCTIONS


// LIBRARIES


// MAIN
export const About = () => {
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <AboutUsSection />
            <Footer />
        </>
    )
}

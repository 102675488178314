import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const Template = styled.div`

    @media only screen and (min-width: 1800px) {

    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 370px) {

    }
`;
import React from 'react'
import { SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { AlignCenter, Container, FlexBoxSA, Headline, Sandwich, SubTitle } from '../../../global/global.styles'
import CTAICON from '../../../assets/icons/call.png'
import { CtaIcon, CtaIconContainer } from '../style/home.styles'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const CTA = () => {
    
    const navigate = useNavigate();

    return (
        <>
            <Container>
                <FlexBoxSA>
                    <CtaIconContainer>
                        <CtaIcon src={CTAICON} />
                    </CtaIconContainer>
                </FlexBoxSA>
                <SpacerTopBottomXL />
                <AlignCenter>
                    <Headline>
                        Do you have a project for us ?
                    </Headline>
                    <SpacerTopBottomXL />
                </AlignCenter>
                <SpacerTopBottomXL />
                <Sandwich>
                    <FlexBoxSA>
                        <Button
                            variant='contained'
                            sx={{
                                textTransform: 'none',
                                backgroundColor: "#307EF3",
                                fontFamily: 'Manrope',
                                fontWeight: 600,
                                fontSize: 18,
                                width: 258,
                                borderRadius: '6px',
                                ':hover': {
                                    bgcolor: '#2C6AC8',
                                }
                            }}
                            onClick={() => navigate("/contact")}>
                            Contact Us
                        </Button>
                    </FlexBoxSA>
                </Sandwich>
            </Container>
        </>
    )
}

export default CTA
import { Route, Routes } from "react-router-dom";
import { About } from "./pages/about/about";
import { Contact } from "./pages/contact/contact";
import Cookies from "./pages/cookies/cookies";
import { Home } from "./pages/home/home";
import NotFound from "./pages/notFound/notFound";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import { Projects } from "./pages/projects/projects";
import { Services } from "./pages/services/services";
import TermsOfUse from "./pages/termsOfUse/termsOfUse";
import { Work } from "./pages/work/work";


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/work" element={<Work />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects/:projectId" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;

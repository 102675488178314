import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import { SpacerLeftRightXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { Container, FlexBoxSA, FlexBoxSB, HeadlineWhite, Sandwich, Squeezer, SubTitleWhite } from '../../../global/global.styles'
import { MobileProcessContainer, ProcessContainer, ProcessSection } from '../style/home.styles'
import ProcessBlock from './processBlock'
import ProcessContent from './processContent'
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../global/components/screenSize'

const Process = () => {

    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    const [hover, setHover] = useState();

    return (
        <>
            {
                width > 850
                    ?
                    <ProcessContainer>
                        <Squeezer>
                            <Squeezer>
                                <HeadlineWhite>
                                    Our Process
                                </HeadlineWhite>
                                <SpacerTopBottomXL />
                                <SubTitleWhite style={{ width: 480 }}>
                                    Through a collaborative process, we craft digital solutions for start-ups with an idea in its infancy to mid-size companies, large enterprises, and even non-profit organizations.
                                </SubTitleWhite>
                                <SpacerTopBottomLarge />
                                <Button
                                    variant='contained'
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: "#307EF3",
                                        fontFamily: 'Manrope',
                                        fontWeight: 600,
                                        fontSize: 18,
                                        width: 258,
                                        borderRadius: '6px',
                                        ':hover': {
                                            bgcolor: '#2C6AC8',
                                        }
                                    }}
                                    onClick={() => navigate("/services")}>
                                    Know more
                                </Button>
                            </Squeezer>
                        </Squeezer>
                    </ProcessContainer>
                    :
                    <MobileProcessContainer>
                        <Squeezer>
                            <Squeezer>
                                <HeadlineWhite>
                                    Our Process
                                </HeadlineWhite>
                                <SpacerTopBottomXL />
                                <SubTitleWhite style={width > 650 ? { width: 320 } : width > 350 ? { width: 280 } : { width: 240 }}>
                                    Through a collaborative process, we craft digital solutions for start-ups with an idea in its infancy to mid-size companies, large enterprises, and even non-profit organizations.
                                </SubTitleWhite>
                                <SpacerTopBottomLarge />
                                <Button
                                    variant='contained'
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: "#307EF3",
                                        fontFamily: 'Manrope',
                                        fontWeight: 600,
                                        fontSize: width > 850 ? 18 : 12,
                                        width: width > 850 ? 258 : 160,
                                        borderRadius: '6px',
                                        ':hover': {
                                            bgcolor: '#2C6AC8',
                                        }
                                    }}
                                    onClick={() => navigate("/services")}>
                                    Know more
                                </Button>
                            </Squeezer>
                        </Squeezer>
                    </MobileProcessContainer>
            }
            <div style={
                width > 900
                    ?
                    { height: 600 }
                    :
                    { height: 'auto' }
            }>
                <Container>
                    <Sandwich>
                        <Grid container style={{ justifyContent: 'space-around' }}>
                            <Grid item xl={4} lg={4} md={4} sm={12} sm={12} style={{ paddingTop: 64 }}>
                                <FlexBoxSA>
                                    <ProcessBlock
                                        title={"Discover"}
                                        subTitle={"Refine your idea and build comprehensive product strategy"}
                                        hovered={hover === "Discover" ? true : false}
                                        content={<ProcessContent
                                            block={hover}
                                        />}
                                        mouseEnter={() => setHover("Discover")}
                                        mouseLeave={() => setHover()}
                                        expand={hover === "Discover" ? true : false}
                                    />
                                </FlexBoxSA>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} sm={12} style={{ paddingTop: 64 }}>
                                <FlexBoxSA>
                                    <ProcessBlock
                                        title={"Design"}
                                        subTitle={"Create visual design"}
                                        hovered={hover === "Design" ? true : false}
                                        content={<ProcessContent
                                            block={hover}
                                        />}
                                        mouseEnter={() => setHover("Design")}
                                        mouseLeave={() => setHover()}
                                        expand={hover === "Design" ? true : false}
                                    />
                                </FlexBoxSA>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} sm={12} style={{ paddingTop: 64 }}>
                                <FlexBoxSA>
                                    <ProcessBlock
                                        title={"Develop"}
                                        subTitle={"Get dedicated team for product development (Mobile & Web)"}
                                        hovered={hover === "Develop" ? true : false}
                                        content={<ProcessContent
                                            block={hover}
                                        />}
                                        mouseEnter={() => setHover("Develop")}
                                        mouseLeave={() => setHover()}
                                        expand={hover === "Develop" ? true : false}
                                    />
                                </FlexBoxSA>
                            </Grid>
                        </Grid>
                    </Sandwich>
                </Container>
            </div>
        </>
    )
}

export default Process
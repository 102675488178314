// DEFAULT
import React from 'react'
import { SpacerTopBottomXL } from '../../design/spacer/spacer'
import Navbar from '../../global/components/navbar'
import ScrollToTop from '../../global/components/scrollToTop'

// STYLES
import { Body, Container, Headline, Sandwich, Squeezer } from '../../global/global.styles'
import { Template } from './style/work.styles'

// COMPONENTS


// FUNCTIONS


// LIBRARIES


// MAIN
export const Work = () => {
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <Container>
                <Sandwich>
                    <Headline>
                        Our Work
                    </Headline>
                    <SpacerTopBottomXL />
                    <Body>
                        Coming Soon...
                    </Body>
                </Sandwich>
            </Container>
        </>
    )
}
